<script setup lang="ts">
import { useAuthStore } from '~/stores/auth/useAuthStore';
import { ROUTES } from '~/constants/routes';

const store = useAuthStore();

const config = useRuntimeConfig();
const favouritesUrl = config.public.favouritesUrl;

const tabsRef = ref<HTMLElement | null>(null);
const tabsContainerRef = ref<HTMLElement | null>(null);
const isScrollable = ref(false);
const showScrollLeft = ref(false);
const showScrollRight = ref(false);
const isLoading = ref(true);

onMounted(() => {
    updateScrollButtons();
    window.addEventListener('resize', updateScrollButtons);
    isLoading.value = false;

    // Вызов scrollToActive в следующем тике
    nextTick(() => {
        scrollToActive();
    });
});

const updateScrollButtons = () => {
    if (tabsRef.value && tabsContainerRef.value) {
        isScrollable.value = tabsRef.value.scrollWidth > tabsContainerRef.value.clientWidth;
        showScrollLeft.value = tabsRef.value.scrollLeft > 0; // Показываем стрелку влево, если не в начале
        showScrollRight.value = tabsRef.value.scrollLeft < (tabsRef.value.scrollWidth - tabsContainerRef.value.clientWidth); // Показываем стрелку вправо, если не в конце
    }
};

const scrollLeft = () => {
    if (tabsRef.value) {
        tabsRef.value.scrollBy({ left: -100, behavior: 'smooth' });
        updateScrollButtons(); // Обновляем состояние кнопок после скролла
    }
};

const scrollRight = () => {
    if (tabsRef.value) {
        tabsRef.value.scrollBy({ left: 100, behavior: 'smooth' });
        updateScrollButtons(); // Обновляем состояние кнопок после скролла
    }
};

const scrollToActive = () : void => {
    const element = (document.querySelectorAll('a.active')[0])?.parentElement?.lastElementChild;
    if(!element) {
        return;
    }

    const active = ref<Element | null>(element);
    active.value?.scrollIntoView({ behavior: 'smooth' });
}

watch(tabsRef, updateScrollButtons);
</script>

<template>
    <v-skeleton-loader
        v-if="isLoading"
        color="#f4f4f5"
        class="loader"
        type="heading, list-item, list-item, list-item, heading, list-item, list-item"/>
    <v-skeleton-loader
        v-if="isLoading"
        color="#f4f4f5"
        class="mobile-loader"
        type="list-item"/>

    <div 
        v-if="!isLoading" 
        ref="tabsContainerRef" 
        class="menu-list-group" 
        :class="{ scrollable: isScrollable }"
    >
        <button 
            :class="{ visible: showScrollLeft }" 
            class="scroll-left" 
            @click="scrollLeft"
        >
            <i class="ri-arrow-left-s-line"/>
        </button>

        <div ref="tabsRef" class="menu-list">
            <div class="menu-header">
                <label class="menu-header-text">{{ $t('routes.menu') }}</label>
            </div>
            <div class="menu-items">
                <NuxtLink 
                    :to="ROUTES.user.link(store.userId)" 
                    activeClass="active" 
                    class="menu-item-link"
                >
                    <i class="menu-item-icon ri-user-line ri-lg"/>
                    <span class="menu-item-text">{{ $t('routes.profile') }}</span>
                </NuxtLink>
                <span class="divider"/>
                <NuxtLink 
                    :to="ROUTES.partners.link" 
                    activeClass="active" 
                    class="menu-item-link"
                >
                    <i class="menu-item-icon ri-vip-crown-2-line ri-lg"/>
                    <span class="menu-item-text">{{ $t('routes.partners') }}</span>
                </NuxtLink>
                <span class="divider"/>
                <a :href="favouritesUrl" class="menu-item-link">
                    <i class="menu-item-icon ri-heart-3-line ri-lg"/>
                    <span class="menu-item-text">{{ $t('routes.favorites') }}</span>
                    <i class="menu-item-icon mobile-visible ri-arrow-right-up-line ri-lg"/>
                </a>
            </div>

            <div v-if="store.isAdministrator" class="menu-header">
                <label class="menu-header-text">{{ $t('routes.administration') }}</label>
            </div>
            <div v-if="store.isAdministrator" class="menu-items">
                <NuxtLink
                    :to="ROUTES.users.link" 
                    activeClass="active" 
                    class="menu-item-link"
                >
                    <i class="menu-item-icon ri-id-card-line ri-lg"/>
                    <span class="menu-item-text">{{ $t('routes.users') }}</span>
                </NuxtLink>
                <span class="divider"/>
            </div>
        </div>
            
        <button 
            :class="{ visible: showScrollRight }" 
            class="scroll-right" 
            @click="scrollRight"
        >
            <i class="ri-arrow-right-s-line"/>
        </button>
    </div>
</template>

<style scoped lang="scss">
    .loader{
        display: flex;
        @include responsive( 'mobile' ) {
            display:none;
        }
    }
    .mobile-loader {
        display:none;
        width: 100%;
        @include responsive( 'mobile' ) {
            display: flex;
        }
    }

    .menu-list-group {
        display: flex;
        flex: inherit;
        overflow: hidden;

        @include responsive( 'mobile' ) {
            flex-direction: row;
            width: 100%;
            max-width: 640px;
            position: relative;
            overflow: auto;
            -ms-overflow-style: none; /*// IE 10+*/
            overflow: -moz-scrollbars-none;/*// Firefox*/
            padding: var(--Padding-pa-2, 8px) var(--Padding-pa-4, 16px) 0px var(--Padding-pa-4, 16px);
            &::-webkit-scrollbar {
                display: none; /*Safari and Chrome*/
            }
        }

        .scroll-left {
            padding: 5px;
            color: var(--Color-Zink-500, $secondary-text-color);
            opacity: 0;
            display: none;

            @include responsive( 'mobile' ) {
                display: flex;
            }
        }

        .scroll-right {
            padding: 5px;
            color: var(--Color-Zink-500, $secondary-text-color);
            opacity: 0;
            display: none;

            @include responsive( 'mobile' ) {
                display: flex;
            }
        }

        .visible {
            opacity: 1;
        }

        .menu-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Padding-pa-6, 24px);

            align-self: stretch;
            background-color: $main-bg-color;

            @include responsive( 'mobile' ) {
                white-space: nowrap;
                flex-direction: row;
                gap: 0;

                overflow-x: auto; /* Позволяем горизонтальный скролл */
                scroll-behavior: smooth; /* Плавный скролл */
                -webkit-overflow-scrolling: touch;
                scrollbar-width: thin;
            }

            .menu-header {
                display: flex;
                padding: 0 12px;
                align-items: center;
                gap: 12px;
                align-self: stretch;

                /** Заголовки в меню для мобильной версии скрываются */
                @include responsive( 'mobile' ) {
                    display: none;
                }

                .menu-header-text {
                    color: var(--Color-Zink-400, $main-text-color);

                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: -0.13px;
                }
            }

            .menu-items {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;

                @include responsive( 'mobile' ) {
                    flex-direction: row;
                }

                .menu-item-link {
                    display: flex;
                    padding: 10px 12px;
                    align-items: center;
                    gap: 8px;

                    @include responsive( 'mobile' ) {
                        margin: 12px 8px 0px 8px;
                        padding: 0px 0px 20px 0px;
                    }

                    .menu-item-icon {
                        width: 20px;
                        height: 20px;
                        color: $main-text-color;

                        @include responsive( 'mobile' ) {
                            display: none;
                        }

                        &.mobile-visible{
                            @include responsive( 'mobile' ) {
                                display: block;
                            }
                        }
                    }

                    .menu-item-text {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: -0.16px;
                        color: $main-text-color;
                    }

                    &.active {
                        @include responsive( 'mobile' ) {
                            border-bottom: 1px solid $hover-link-color;
                        }

                        .menu-item-icon {
                            color: $main-active-color;
                        }
                        .menu-item-text {
                            color: $main-active-color;
                        }
                    }
                }
            }
        }
    }
</style>
